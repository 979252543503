<template>
  <div id="home">
    <h1 class="hidden-item">Homepage - Colfert S.p.A.</h1>
    <HeroSection class="hero-section" />
    <HomeSolutions class="home-solutions-section" />
    <!-- <HomeWork class="home-work-section" /> -->
    <HomeLogin
      class="home-login-section"
      v-if="accountBlockContent"
      :account-data="accountBlockContent"
    />
    <!-- <HomeSolutions class="home-solutions-section" /> -->
    <HomeTraining
      v-if="trainingBlockContent"
      :training-data="trainingBlockContent"
      class="home-training-section"
    />
    <HomeNewsDisplay
      v-if="newProductsBlockContent"
      :new-products-block="newProductsBlockContent"
      class="home-news-display"
      title="title"
      slider-id="home-news-display"
    />
    <HomeBestSelling
      v-if="bestSellerSliderContent"
      :best-selling-products="bestSellerSliderContent"
      class="home-best-selling"
      title="title"
      slider-id="home-best-selling"
    />
    <HomeRecentProducts
      class="home-recent-products"
      :products="recentProducts"
      v-if="isAuthenticated && recentProducts && recentProducts.length > 0"
    />
    <HomeBrand
      v-if="brandsBlockContent"
      :brands-block="brandsBlockContent"
      class="home-brand-section"
    />
    <HomeNews
      v-if="newsBlockContent"
      :news-block-content="newsBlockContent"
      class="home-news-section"
    />
  </div>
</template>

<script type="module">
import HeroSection from '~/components/HeroSection.vue';
import {
  defineComponent,
  useFetch,
  onMounted,
  useMeta,
} from '@nuxtjs/composition-api';
import { useGtm, useUser, useHome } from '~/composables';
import { HomeBlocks } from '~/components/Home';
import { useCache, CacheTagPrefix } from '~/composables/useCache/useCache';

export default defineComponent({
  name: 'HomePage',
  components: {
    HeroSection,
    ...HomeBlocks,
  },
  head: {},
  setup() {
    const { isAuthenticated } = useUser();
    const { pageViewPush } = useGtm();
    const { addTags } = useCache();
    const {
      loadRecentProducts,
      loadHomeBlocks,
      loadStructuredData,
      accountBlockContent,
      trainingBlockContent,
      newProductsBlockContent,
      bestSellerSliderContent,
      brandsBlockContent,
      newsBlockContent,
      recentProducts,
      homeMeta,
    } = useHome();

    useFetch(async () => {
      const homeBlocksLoadedSuccessfully = await loadHomeBlocks();
      const structuredDataWasLoadedSuccessfully = await loadStructuredData();
      if (homeBlocksLoadedSuccessfully && structuredDataWasLoadedSuccessfully) {
        addTags([{ prefix: CacheTagPrefix.View, value: 'homepage' }]);
      }
    });

    onMounted(() => {
      loadRecentProducts();
      pageViewPush('HomePage');
    });

    useMeta(() => homeMeta.value);

    return {
      accountBlockContent,
      isAuthenticated,
      recentProducts,
      trainingBlockContent,
      newProductsBlockContent,
      bestSellerSliderContent,
      brandsBlockContent,
      newsBlockContent,
    };
  },
});
</script>
